import React from "react";
import { Typography } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { CategoryExpense } from "~shared/api/endpoints/Statitstics";
import { EUR } from "~shared/helpers/currency";
import { Widget } from "~shared/ui";

type ExpensesCategoryListProps = {
  data: CategoryExpense[];
  total: number;
  buildDetailUrl: (categoryId: number) => string;
};

export const ExpensesCategoryList: React.FC<ExpensesCategoryListProps> = (
  props
) => {
  const { buildDetailUrl, data, total } = props;

  return (
    <Widget title="By Category:">
      {data.map((data) => {
        const percentage =
          (parseFloat(data.total) / parseFloat(String(total))) * 100;
        const Formatter = new Intl.NumberFormat("en", {
          maximumFractionDigits: 2,
        });

        return (
          <Link to={buildDetailUrl(data.categoryId)}>
            <ListItem key={data.categoryId}>
              <Typography.Text>
                {data.categoryName}{" "}
                <Typography.Text type="secondary">
                  ({Formatter.format(percentage)}%)
                </Typography.Text>
              </Typography.Text>
              <Typography.Text>
                {EUR.format(parseFloat(data.total))}
              </Typography.Text>
            </ListItem>
          </Link>
        );
      })}
    </Widget>
  );
};

const ListItem = styled.li`
  display: flex;
  flex: 1 0 100%;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.antd.colorBorderSecondary};
  padding: ${({ theme }) => theme.antd.paddingSM}px;
  cursor: pointer;
`;
