import React from "react";
import styled from "styled-components";
import { Flex, Segmented } from "antd";
import { PageLayout } from "~shared/ui";
import {
  useExpensesQuery,
  usePlotDataQuery,
  useStatisticsConfig,
  useTotalExpensesQuery,
} from "~shared/api/queries/statistics";
import {
  buildDetailUrl,
  createPlotData,
  shouldDisableBackwards,
  shouldDisableForward,
  VIEW_TO_DATE_TRANSFORMER,
} from "./helpers";
import { ChartsWidget, ExpensesCategoryList, Summary } from "./ui";
import { Period } from "./types";
import { usePeriodData } from "./hooks";
import { dayjs, formatDate } from "~shared/helpers";
import { PERIOD_OPTIONS } from "./constants";

export const StatisticsPage: React.FC = () => {
  const [period, setPeriod] = React.useState<Period>("week");
  const { timeFrame, moveTimeFrame } = usePeriodData({ period });

  const maxDate = formatDate(timeFrame.max);
  const minDate = formatDate(timeFrame.min);

  const { data, isLoading } = usePlotDataQuery(period, {
    maxDate,
    minDate,
  });
  const { data: expenses } = useExpensesQuery({
    maxDate,
    minDate,
  });

  const { data: config, isLoading: isLoadingConfig } = useStatisticsConfig();
  const { data: total } = useTotalExpensesQuery({
    maxDate,
    minDate,
  });

  const barsData = React.useMemo(
    () =>
      createPlotData({
        data,
        dateTransformer: VIEW_TO_DATE_TRANSFORMER[period],
      }),
    [data, period]
  );

  return (
    <PageLayout title="Statistics">
      <Wrapper>
        <Graph>
          <Summary
            period={period}
            timeFrame={timeFrame}
            total={total?.total ?? 0}
          />
          <ChartsWidget
            barsData={barsData ?? []}
            pieData={expenses ?? []}
            isLoading={isLoading || isLoadingConfig}
            period={period}
            moveTimeFrame={moveTimeFrame}
            disableForward={shouldDisableForward(timeFrame.min, period)}
            disableBackwards={shouldDisableBackwards(
              timeFrame.max,
              period,
              dayjs(config?.minDate)
            )}
          />
          <Segmented
            value={period}
            onChange={(value) => setPeriod(value)}
            block
            options={PERIOD_OPTIONS}
          />
        </Graph>
        <ExpensesCategoryList
          total={total?.total || NaN}
          buildDetailUrl={(id) => buildDetailUrl(timeFrame, id)}
          data={expenses ?? []}
        />
      </Wrapper>
    </PageLayout>
  );
};

const Wrapper = styled(Flex).attrs({ vertical: true })`
  gap: ${({ theme }) => theme.antd.marginLG}px;
`;

const Graph = styled(Flex).attrs({ vertical: true })`
  gap: ${({ theme }) => theme.antd.marginXS}px;
`;
