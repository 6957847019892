import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTransactionsQuery } from "~shared/api/queries";
import { BackIcon, EmptyData, PageLayout } from "~shared/ui";
import { FilterDrawer, TransactionsList } from "./ui";
import { FileAddOutlined } from "@ant-design/icons";
import { useFilters } from "~shared/contexts/FiltersContext";
import { getGrouppedTransactions } from "./helpers";

export const TransactionsPage = () => {
  const navigate = useNavigate();
  const { filters } = useFilters();
  const { data, hasNextPage, fetchNextPage, isLoading } = useTransactionsQuery({
    page: 1,
    limit: 20,
    filters,
  });

  const transactions = React.useMemo(
    () => getGrouppedTransactions(data?.pages),
    [data]
  );

  return (
    <>
      <PageLayout
        title="Transactions"
        padded={true}
        isLoading={isLoading}
        withNavigation={false}
        HeaderStart={
          <Link to="/app">
            <BackIcon />
          </Link>
        }
        HeaderEnd={<FilterDrawer />}
        floatingButton={floatingButtonConfig}
      >
        {!transactions?.length ? (
          <EmptyData
            buttonLabel="Create Transaction"
            description="Seems like you don't have any transactions yet, create one!"
            onClick={() => navigate("/app/transactions/new")}
          />
        ) : (
          <TransactionsList
            canFetch={hasNextPage}
            fetchMore={fetchNextPage}
            data={transactions}
            linkBuilder={(transaction) => `/app/transactions/${transaction.id}`}
          />
        )}
      </PageLayout>
    </>
  );
};

const floatingButtonConfig = {
  icon: <FileAddOutlined />,
  link: "/app/transactions/new",
};
