import React from "react";
import { Flex, Spin } from "antd";
import styled from "styled-components";

type LoadMoreObserverProps = {
  onDisplay: () => void;
};

export const LoadMoreObserver: React.FC<LoadMoreObserverProps> = (props) => {
  const { onDisplay } = props;
  const componentRef = React.useRef(null);
  const observerRef = React.useRef(
    new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          onDisplay();
        }
      },
      { threshold: 0.1 }
    )
  );

  React.useEffect(() => {
    const ref = componentRef.current;
    const observer = observerRef.current;

    if (ref) {
      observer.observe(ref);
    }

    return () => {
      if (ref) {
        observer.unobserve(ref);
      }
    };
  }, [onDisplay]);

  return (
    <Container ref={componentRef}>
      <Spin />
    </Container>
  );
};

const Container = styled(Flex).attrs({ align: "center", justify: "center" })`
  padding: ${({ theme }) =>
    `${theme.antd.paddingMD}px ${theme.antd.paddingMD}px ${theme.antd.paddingMD}px ${theme.antd.paddingMD}px`};
`;
