import React from "react";
import { List } from "antd";
import dayjs from "dayjs";
import { DateTransactions } from "../../types";
import { LoadMoreObserver, Widget } from "~shared/ui";
import { TransactionListItem } from "./TransactionListItem";
import styled from "styled-components";
import { Transaction } from "~shared/api/endpoints/Transactions";
import { Link } from "react-router-dom";

type TransactionsGroupProps = {
  data: DateTransactions[];
  fetchMore: () => void;
  canFetch: boolean;
  linkBuilder: (transaction: Transaction) => string;
};

export const TransactionsList: React.FC<TransactionsGroupProps> = (props) => {
  const { canFetch, data, fetchMore, linkBuilder } = props;

  return (
    <List
      dataSource={data}
      rowKey={(item) => item.date}
      loadMore={canFetch ? <LoadMoreObserver onDisplay={fetchMore} /> : null}
      renderItem={(item) => (
        <StyledWidget title={dayjs(item.date).format("DD MMMM")}>
          {item.transactions.map((transaction, index) => (
            <Link key={transaction.id} to={linkBuilder(transaction)}>
              <TransactionListItem
                transaction={transaction}
                showBorder={index !== item.transactions.length - 1}
              />
            </Link>
          ))}
        </StyledWidget>
      )}
    />
  );
};

const StyledWidget = styled(Widget)`
  margin-bottom: ${({ theme }) => theme.antd.marginMD}px;
`;
