import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import updateLocale from "dayjs/plugin/updateLocale";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);

dayjs.extend(updateLocale);
dayjs.extend(timezone);
dayjs.updateLocale("en", {
  weekStart: 1,
});

export { dayjs };
