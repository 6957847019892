import React from "react";
import { useMutation } from "@tanstack/react-query";
import { Button, Card, Form, Input, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as Auth from "~shared/api/endpoints/Auth";

export const AuthPage = () => {
  const [error, setError] = React.useState<string | null>(null);

  const navigate = useNavigate();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: Auth.login,
    mutationKey: ["login"],
  });

  React.useEffect(() => {
    if (localStorage.getItem("access_token")) {
      navigate("/app");
    }
  }, [navigate]);

  const handleSubmit = async (data: { email: string; password: string }) => {
    try {
      const response = await mutateAsync({
        email: data.email,
        password: data.password,
      });
      localStorage.setItem("access_token", response.accessToken);
      navigate("/app");
    } catch (e: unknown) {
      const error = e as Error;
      if (error.message === "401") {
        setError("Incorrect email or password");
      }
    }
  };

  return (
    <Container>
      <StyledCard size="small">
        <Form
          size="large"
          name="login"
          layout="vertical"
          onFinish={handleSubmit}
          disabled={isPending}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input placeholder="your@email.com" />
            {error && <Typography.Text type="danger">{error}</Typography.Text>}
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input.Password placeholder="*******" />
          </Form.Item>

          <ButtonsContainer>
            <Form.Item style={{ width: "100%", margin: 0 }}>
              <Button
                loading={isPending}
                size="large"
                block
                type="primary"
                htmlType="submit"
              >
                Log In
              </Button>
            </Form.Item>
            <Link to="/register" style={{ width: "100%" }}>
              <Button
                style={{ width: "100%" }}
                variant="outlined"
                htmlType="submit"
              >
                Create Account
              </Button>
            </Link>
          </ButtonsContainer>
        </Form>
      </StyledCard>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.antd.marginXS}px;
`;

const StyledCard = styled(Card)`
  width: 70vw;
`;
