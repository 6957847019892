import React from "react";
import { Flex, List, Typography } from "antd";
import styled, { css } from "styled-components";
import { Transaction } from "~shared/api/endpoints/Transactions";

type TransactionListItemProps = {
  transaction: Transaction;
  showBorder: boolean;
};

export const TransactionListItem: React.FC<TransactionListItemProps> = (
  props
) => {
  const {
    transaction: { amount, name },
    showBorder,
  } = props;
  console.log(showBorder);
  return (
    <StyledListItem $showBorder={showBorder}>
      <Flex align="space-between" justify="space-between" flex={1}>
        <Flex align="center" justify="space-between" flex={1}>
          <Title>{name}</Title>
          <Amount strong>{parseFloat(amount).toFixed(2)} €</Amount>
        </Flex>
      </Flex>
    </StyledListItem>
  );
};

const StyledListItem = styled(List.Item)<{ $showBorder: boolean }>`
  padding: ${({ theme }) =>
    `${theme.antd.paddingMD}px ${theme.antd.paddingXL}px`} !important;

  ${({ $showBorder }) =>
    $showBorder &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.antd.colorBorder} !important;
    `}
`;

const Title = styled(Typography.Text)`
  font-size: ${({ theme }) => theme.antd.fontSizeLG}px;
  text-transform: capitalize;
`;

const Amount = styled(Typography.Text)`
  font-size: ${({ theme }) => theme.antd.fontSizeLG}px;
`;
