import { InfiniteData } from "@tanstack/react-query";
import dayjs from "dayjs";
import { Transaction } from "~shared/api/endpoints/Transactions";
import { PaginatedResponse } from "~shared/api/types";
import { DateTransactions } from "../types";

type Pages = InfiniteData<PaginatedResponse<Transaction>>["pages"];

export const getGrouppedTransactions = (pages?: Pages) => {
  if (!pages) {
    return [];
  }

  const transactions =
    pages
      .flat()
      .map(({ data }) => data)
      .flat() ?? [];

  return transactions.reduce<DateTransactions[]>((aggr, transaction) => {
    const date = dayjs(transaction.date).tz();
    const key = date.format("YYYY-MM-DD");

    const dateIndex = aggr.findIndex((group) => group.date === key);
    if (dateIndex !== -1) {
      aggr[dateIndex].transactions.push(transaction);
      return aggr;
    }

    aggr.push({
      date: key,
      transactions: [transaction],
    });
    return aggr;
  }, []);
};
