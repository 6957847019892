import React from "react";
import { Card, theme, Typography } from "antd";
import { ResponsiveBar } from "@nivo/bar";
import { PlotData } from "~shared/api/endpoints/Statitstics";
import { EUR } from "~shared/helpers/currency";

type BarChartProps = {
  data?: PlotData[];
  maxValue: number;
  average: number;
};

export const BarChart: React.FC<BarChartProps> = (props) => {
  const { data, maxValue, average } = props;

  const { token } = theme.useToken();

  return (
    <ResponsiveBar
      data={data ?? []}
      indexBy={"date"}
      keys={["total"]}
      axisLeft={null}
      borderRadius={3}
      colors={[`${token.colorPrimary}30`]}
      borderWidth={1}
      labelSkipHeight={1}
      padding={0.3}
      margin={{
        bottom: 35,
        top: 30,
        left: 10,
        right: 10,
      }}
      markers={[
        {
          value: maxValue,
          legendOrientation: "horizontal",
          legendPosition: "top",
          legend: `Max - ${EUR.format(maxValue)}`,
          axis: "y",
          textStyle: { fontSize: 9 },
          lineStyle: {
            stroke: token.colorErrorBgActive,
            strokeWidth: 1,
            strokeDasharray: 5,
          },
        },
        {
          value: average,
          legendOrientation: "horizontal",
          legendPosition: "bottom-right",
          legend: `Avg - ${EUR.format(average)}`,
          axis: "y",
          textStyle: { fontSize: 9 },
          lineStyle: {
            stroke: token.colorPrimaryBorder,
            strokeWidth: 1,
            strokeDasharray: 5,
          },
        },
      ]}
      enableGridY={false}
      enableGridX={false}
      labelSkipWidth={50}
      valueFormat={(value) => EUR.format(value)}
      tooltip={({ data }) => (
        <Card size="small" style={{ padding: 5 }}>
          <Typography.Text>
            {EUR.format(parseFloat(data.total))}
          </Typography.Text>
        </Card>
      )}
    />
  );
};
