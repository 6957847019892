import React from "react";
import { Segmented } from "antd";
import styled from "styled-components";
import { BarChartOutlined, PieChartOutlined } from "@ant-design/icons";
import { ArrowButton, BarChart, PieChart, Widget } from "~shared/ui";
import { CategoryExpense, PlotData } from "~shared/api/endpoints/Statitstics";
import { Period } from "../types";
import { TimeFrameNavigator } from "../hooks";

type ChartType = "bars" | "pie";

type BarsData = {
  plot: PlotData[];
  max: number;
  avg: number;
};

type ChartsWidgetProps = {
  barsData: BarsData;
  pieData: CategoryExpense[];
  isLoading: boolean;
  period: Period;
  moveTimeFrame: TimeFrameNavigator;
  disableForward?: boolean;
  disableBackwards?: boolean;
};

export const ChartsWidget: React.FC<ChartsWidgetProps> = (props) => {
  const {
    barsData,
    disableBackwards,
    disableForward,
    moveTimeFrame,
    isLoading,
    pieData,
  } = props;

  const [chartType, setChartType] = React.useState<ChartType>("bars");

  return (
    <Widget isLoading={isLoading}>
      <Container>
        {!disableBackwards && (
          <ArrowLeft onClick={() => moveTimeFrame("backwards")} />
        )}
        {!disableForward && (
          <ArrowRight onClick={() => moveTimeFrame("forward")} />
        )}
        <Buttons
          options={CHART_OPTIONS}
          value={chartType}
          onChange={(type) => setChartType(type as ChartType)}
        />
        {chartType === "bars" ? (
          <BarChart
            data={barsData.plot}
            maxValue={barsData.max}
            average={barsData.avg}
          />
        ) : (
          <PieChart data={pieData ?? []} />
        )}
      </Container>
    </Widget>
  );
};

const CHART_OPTIONS: { value: ChartType; icon: React.ReactNode }[] = [
  { value: "bars", icon: <BarChartOutlined /> },
  { value: "pie", icon: <PieChartOutlined /> },
];

const Container = styled.div`
  width: 100%;
  height: 39dvh;
  position: relative;
`;

const Buttons = styled(Segmented)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
`;

const ArrowLeft = styled(ArrowButton)`
  position: absolute;
  left: ${({ theme }) => theme.antd.marginSM}px;
  top: 50%;
  transform: translateY(-50%);
  z-index: ${({ theme }) => theme.antd.zIndexPopupBase};
`;

const ArrowRight = styled(ArrowButton)`
  position: absolute;
  right: ${({ theme }) => theme.antd.marginSM}px;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  z-index: ${({ theme }) => theme.antd.zIndexPopupBase};
`;
